<template>
  <div style="margin-bottom: 60px">
    <div class="odertops">
      <ul class="lefttop">
        <li
          class="list"
          v-for="(item, index) in tabber"
          :key="index"
          @click="tocard(index)"
          :style="
            active == index
              ? 'border-bottom:2px solid #00A3E0;color:#00A3E0;'
              : ''
          "
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="sherch">
        <input
          type="text"
          class="inputs"
          placeholder="请输入商品名称或订单号"
          v-model="parms.keywords" @keyup.enter='tocard(100)'
        />
        <div class="btns" @click="tocard(100)">搜索</div>
      </div>
    </div>
    <div class="tabbarcenter">
      <div class="tabtops">
        <div class="benes widthsone">商品</div>
        <div class="benes widthstwo">单价</div>
        <div class="benes widthsther">数量</div>
        <div class="benes widthstfor">实付款</div>
        <div class="benes widthstfor">状态</div>
        <div class="benes widthstfav">操作</div>
      </div>
      <div class="tabberlis" v-for="(ims, idx) in oderList" :key="idx">
        <div class="lsittop">
          <span class="name">订单号:{{ ims.sn }}</span
          ><span class="dates">{{ ims.createTime }}</span>
        </div>
        <div class="googbottom">
          <div class="leftlsits" ref="alllist">
            <div
              class="goodslist"
              v-for="(item, index) in ims.orderItems"
              :key="index"
            >
              <div class="goodsones">
                <img class="imgtops" :src="item.image" alt="" />
                <div class="goodstext">
                  <div class="tops">
                    {{ item.name }}
                  </div>
                  <div class="skus">{{ item.simpleSpecs }}</div>
                </div>
              </div>
              <div class="price" v-if="item.promotionType != 'POINTS_GOODS'">￥{{ item.goodsPrice }}</div>
              <div class="price" v-else>{{ item.point }}积分</div>
              <div class="nums">{{ item.num }}</div>
            </div>
          </div>
          <div class="shiji" :style="'height:' + height + 'px;'" v-if="ims.orderPromotionType != 'POINTS'">
            ￥{{ ims.flowPrice }}
          </div>
          <div class="shiji" :style="'height:' + height + 'px;'" v-else>
            {{ ims.payPoint }}积分
          </div>
          <div class="status" :style="'height:' + height + 'px;'">
            <span v-if="ims.orderStatus == 'UNPAID'">未付款</span>
            <span v-if="ims.orderStatus == 'PAID'">已付款</span>
            <span v-if="ims.orderStatus == 'UNDELIVERED'">待发货</span>
            <span v-if="ims.orderStatus == 'DELIVERED'">待收货</span>
            <span v-if="ims.orderStatus == 'COMPLETED'">已完成</span>
            <span v-if="ims.orderStatus == 'CANCELLED'">已取消</span>
          </div>
          <div class="caozuo">
            <div class="btns" @click="todetails(ims, ims.ispinglun)">
              订单详情
            </div>
            <div
              class="btns"
              v-if="ims.orderStatus == 'DELIVERED'"
              @click="okoderlsits(ims)"
            >
              确认收货
            </div>
            <div
              class="btns"
              v-if="
                ims.orderStatus == 'UNPAID' ||
                ims.orderStatus == 'PAID' ||
                ims.orderStatus == 'UNDELIVERED'
              "
              @click="oderListcancelApi(ims)"
            >
              取消订单
            </div>
            <div
              class="btns"
              v-if="ims.orderStatus == 'DELIVERED' && ims.isafter && ims.orderPromotionType != 'POINTS'"
              @click="toshouhou(ims)"
            >
              申请售后
            </div>
            <div
              class="btns"
              v-if="ims.orderStatus == 'COMPLETED' && ims.ispinglun"
              @click="todetails(ims, ims.ispinglun)"
            >
              评价订单
            </div>
            <div
              class="btns"
              v-if="ims.orderStatus == 'UNPAID'"
              @click="tocartlist(ims)"
            >
              立即支付
            </div>
          </div>
          <div
            style="font: 0px/0px sans-serif; clear: both; display: block"
          ></div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          text-align: right;
          margin-top: 40px;
          margin-bottom: 30px;
        "
      >
        <el-pagination
          background
          layout="prev, pager, next"
          :total="alltotal"
          @current-change="currentchange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="选择售后商品" :visible.sync="tabbarVisible">
      <el-table :data="goodtabbar" @row-click="rowclick">
        <el-table-column property="name" label="商品名称"></el-table-column>
        <el-table-column property="goodsPrice" label="价格"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="选择支付方式"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="zhifu">
        <!-- <div class="wecart">
          <img class="cartimg" src="../../assets/img/weiixn.png" alt="" />
          <el-radio v-model="radio" label="WECHAT">微信支付</el-radio>
        </div> -->
        <div class="alply">
          <img class="alplyimg" src="../../assets/img/zhifubao.png" alt="" />
          <el-radio v-model="radio" label="ALIPAY">支付宝支付</el-radio>
        </div>
      </div>
      <div style="text-align: center">
        <img
          v-if="wecartimg"
          style="
            width: 150px;
            height: 150px;
            margin-left: 10px;
            margin-top: 20px;
          "
          :src="imgurl + wecartimg"
          alt=""
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ookzhifus">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  oderList,
  oderListcancel,
  paycashier,
  postreceiving,
  getcashierresult,
	getreads
} from "../../request/homeApi";
import http from "@/request/http";
export default {
  data() {
    return {
      goodtabbar: [],
      tabbarVisible: false,
      sn: "",
      radio: "",
      centerDialogVisible: false,
      tabber: [
        {
          name: "全部",
          value: "ALL",
        },
        {
          name: "待付款",
          value: "WAIT_PAY",
        },
        {
          name: "待发货",
          value: "WAIT_SHIP",
        },
        {
          name: "待收货",
          value: "WAIT_ROG",
        },
        {
          name: "已完成",
          value: "COMPLETE",
        },
        {
          name: "已取消",
          value: "CANCELLED",
        },
      ],
      active: 0,
      oderList: [],
      height: 0,
      alltotal: 0,
      tiems: null,
      wecartimg: "",
      parms: {
        pageNumber: 1,
        pageSize: 10,
        tag: "ALL",
        keywords: "",
      },
      reason: "",
    };
  },
  mounted() {
    this.imgurl = http.baseURL;
    if (this.$route.query.type) {
      this.parms.tag = this.$route.query.type;
      this.tabber.forEach((item, index) => {
        if (item.value == this.$route.query.type) {
          this.active = index;
        }
      });
    }
		if(this.$route.query.code>0){
			this.read()
		}
    // this.height = this.$refs.alllist.clientHeight;
    this.oderListApi();
  },
  methods: {
		//消息已读
		async read(){
			let res=await getreads(0)
			console.log(res)
		},
    ookzhifus() {
      let strings = {
        clientType: "PC",
        orderType: "ORDER",
        paymentClient: "NATIVE",
        paymentMethod: this.radio,
        sn: this.sn,
      };
      paycashier(strings)
        .then((res) => {
          if (res.code == 200) {
            // this.centerDialogVisible = false;
            if (this.radio == "WECHAT") {
              this.wecartimg = res.result;
              this.tiems = setInterval(() => {
                this.getcashierresultApi(this.sn);
              }, 1000);
            } else {
              document.querySelector("body").innerHTML = res.result;
              document.forms[0].submit();
            }
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    getcashierresultApi(sn) {
      let obj = {
        clientType: "PC",
        orderType: "ORDER",
        sn,
      };
      getcashierresult(obj)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (res.result) {
              clearInterval(this.tiems);
              this.centerDialogVisible = false;
              this.oderListApi();
            }
          }
        })
        .catch((res) => {
          clearInterval(this.tiems);
        });
    },

    // 立即支付
    tocartlist(item) {
      this.sn = item.sn;
      this.centerDialogVisible = true;
    },
    // 取消订单
    oderListcancelApi(item) {
      this.$confirm("此操作将取消订单 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let data = {
            orderSn: item.sn,
            reason: this.reason,
          };
          oderListcancel(data)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "取消订单成功!",
                });
                this.parms.pageNumber = 1;
                this.oderListApi();
              }
            })
            .catch((res) => {
              console.log("请求失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 点击分页
    currentchange(index) {
      this.parms.pageNumber = index;
      this.oderListApi();
    },
    // 订单列表
    oderListApi() {
      oderList(this.parms)
        .then((res) => {
          if (res.code == 200) {
            this.alltotal = res.result.total;
            res.result.records.forEach((element) => {
              element.ispinglun = false;
              element.orderItems.forEach((item) => {
                if (item.commentStatus == "UNFINISHED") {
                  element.ispinglun = true;
                }
                if (item.afterSaleStatus == "NOT_APPLIED") {
                  element.isafter = true;
                }
              });
            });
            this.oderList = res.result.records;
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    rowclick(e) {
      // console.log(e)
      this.tabbarVisible = false;
      this.$router.push({
        path: "/mepages/aftersale?orderSn=" + this.sn + "&ids=" + e.goodsId,
      });
    },
    // 确认收货
    okoderlsits(item) {
      postreceiving(item.sn)
        .then((res) => {
          if (res.code == 200) {
            this.oderListApi();
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    // 申请售后
    toshouhou(item) {
      this.tabbarVisible = true;
      this.goodtabbar = item.orderItems;
      this.sn = item.sn;
    },
    todetails(item, ispinglun) {
      let step = "";
      if (ispinglun) {
        step = 4;
      }
      if (item.orderStatus == "COMPLETED" && !item.ispinglun) {
        step = 5;
      }
      this.$router.push({
        path: "/mepages/myoderdetail?orderSn=" + item.sn + "&step=" + step,
      });
    },
    tocard(index) {
      this.parms.pageNumber = 1;
      if (index != 100) {
        this.parms.tag = this.tabber[index].value;
        this.active = index;
      }

      this.oderListApi();
    },
  },
};
</script>

<style lang="scss" scoped>
.odertops {
  display: flex;
  align-items: center;
  height: 63px;
  padding: 0 20px;
  justify-content: space-between;
  background: #ffffff;
  .lefttop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    cursor: pointer;
    .list {
      height: 100%;
      padding: 0 15px;
      line-height: 63px;
      font-size: 14px;
      color: #535353;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .sherch {
    display: flex;
    align-items: center;
    width: 300px;
    height: 36px;
    background: #ffffff;

    .inputs {
      width: 220px;
      height: 36px;
      padding: 10px 15px;
      line-height: 36px;
      font-size: 14px;
      color: #999999;
      border: 1px solid #e5e5e5e5;
    }
    .btns {
      width: 80px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      background: #00a3e0;
      border: 1px solid #00a3e0;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.tabbarcenter {
  margin-top: 10px;
  background: #ffffff;
  padding: 15px 17px;
  .tabtops {
    width: 100%;
    height: 36px;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    padding-left: 15px;
    .benes {
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      text-align: center;
      color: #535353;
    }
    .widthsone {
      width: 400px;
      text-align: left;
      padding-left: 80px;
    }
    .widthstwo {
      width: 100px;
    }
    .widthsther {
      width: 100px;
    }
    .widthstfor {
      width: 120px;
    }
    .widthstfav {
      width: 120px;
    }
  }
  .tabberlis {
    margin-top: 10px;
    border: 1px solid #f0f0f0;
    .lsittop {
      width: 100%;
      height: 36px;
      background: #f3f3f3;
      padding: 0 15px;
      line-height: 36px;
      font-size: 12px;
      color: #535353;
      .dates {
        padding-left: 22px;
        font-size: 12px;
        color: #818181;
      }
    }
    .googbottom {
      display: flex;
      align-items: center;

      height: 100%;
      .leftlsits {
        height: 100%;
        .goodslist {
          padding-left: 15px;
          width: 620px;
          height: 118px;
          border-top: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
          .goodsones {
            display: flex;
            align-items: center;
            .imgtops {
              width: 135px;
              height: 72px;
              margin-right: 10px;
            }
            .goodstext {
              .tops {
                width: 256px;
                font-size: 14px;
                color: #535353;
              }
              .skus {
                margin-top: 10px;
                font-size: 14px;
                color: #818181;
              }
            }
          }
          .price {
            width: 140px;
            height: 118px;
            line-height: 118px;
            text-align: center;
            font-size: 14px;
            color: #535353;
          }
          .nums {
            width: 140px;
            height: 118px;
            line-height: 118px;
            text-align: center;
            font-size: 14px;
            color: #535353;
            border-right: 1px solid #f0f0f0;
          }
        }
      }

      .shiji {
        width: 115px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #535353;
        border-right: 1px solid #f0f0f0;
      }
      .status {
        width: 115px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #535353;
        border-right: 1px solid #f0f0f0;
      }
      .caozuo {
        width: 115px;
        color: #00a3e0;
        font-size: 14px;
        .btns {
          width: 100%;
          text-align: center;
          margin-bottom: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
.zhifu {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .wecart {
    display: flex;
    align-items: center;
    margin-right: 33px;
    .cartimg {
      width: 38px;
      height: 34px;
      margin-right: 11px;
    }
  }
  .alply {
    display: flex;
    align-items: center;
    .alplyimg {
      width: 34px;
      height: 34px;
      margin-right: 11px;
    }
  }
}
</style>