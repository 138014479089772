import { render, staticRenderFns } from "./myoder.vue?vue&type=template&id=0ff32877&scoped=true&"
import script from "./myoder.vue?vue&type=script&lang=js&"
export * from "./myoder.vue?vue&type=script&lang=js&"
import style0 from "./myoder.vue?vue&type=style&index=0&id=0ff32877&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ff32877",
  null
  
)

export default component.exports